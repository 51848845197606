import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [title, setTitle] = useState('Personal Information');
  const [errorMessage, setErrorMessage] = useState(''); // State to track error message
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Update the title when the route changes
    const session = sessions.find(session => session.path === location.pathname);
    if (session) {
      setTitle(session.title);
      setErrorMessage(''); // Clear error message when route changes
    }
  }, [location.pathname]);

  const sessions = [
    { id: 0, title: 'Instructions', path: '/application/instructions' },
    { id: 1, title: 'Personal Information', path: '/application/personal-information' },
    { id: 2, title: 'Academic Details', path: '/application/academic-information' },
    { id: 3, title: 'Professional Details', path: '/application/professional-information' },
    { id: 4, title: 'Resume', path: '/application/resume' },
    { id: 5, title: 'Personal Statement', path: '/application/personal-statement' },
    { id: 6, title: 'Reference Letter', path: '/application/reference-letter' },
    { id: 7, title: 'Funding Options', path: '/application/funding-options' },
    { id: 8, title: 'Privacy Policy Statement', path: '/application/privacy-policy-statement' },
  ];

  const checkApplicationExists = () => {
    const applicationId = localStorage.getItem('id');
    return  applicationId !== null && applicationId !== "undefined" && applicationId !== "" 
  };
  
  const checkRefrenceLetterExists = () => {
    const Refrence_letter = localStorage.getItem('Refrence_letter');
    console.log("Refrence_letter",Refrence_letter)
    return Refrence_letter !== null && Refrence_letter !== "undefined" && Refrence_letter !== ""; // Return true if application ID exists, otherwise false
  };

  const checkAllExists = () => {
    const applicationId = localStorage.getItem('id');
    const academic_detail = localStorage.getItem('acedemic_detail');
    const reference_letter = localStorage.getItem('Refrence_letter');
    const personal_statement = localStorage.getItem('personal_statement');
    const professional_detail = localStorage.getItem('professional_detail');
    const resume = localStorage.getItem('resume');
  
    console.log("applicationId", applicationId);
    console.log("academic_detail", academic_detail);
    console.log("reference_letter", reference_letter);
    console.log("personal_statement", personal_statement);
    console.log("professional_detail", professional_detail);
    console.log("resume", resume);
  
    // Check explicitly for null, undefined, or empty strings
    const allExists = (
      applicationId !== null && applicationId !== "undefined" && applicationId !== "" &&
      academic_detail !== null && academic_detail !== "undefined" && academic_detail !== "" &&
      reference_letter !== null && reference_letter !== "undefined" && reference_letter !== "" &&
      personal_statement !== null && personal_statement !== "undefined" && personal_statement !== "" &&
      professional_detail !== null && professional_detail !== "undefined" && professional_detail !== "" &&
      resume !== null && resume !== "undefined" && resume !== ""
    );
  
    console.log("All exists:", allExists); // Add this for better debugging
    return allExists;
  };
  
  

  const handleNavigation = (session) => {
    // Skip the check for the first and last session
    if (session.id === 1 || session.id === 0) {
      navigate(session.path);
    } else if (session.id === 8) {
      if (checkAllExists()) {
        navigate(session.path);
      } else {
        setErrorMessage("Please fill in all forms first.");
      }
    }else if (session.id === 7){
      if (checkRefrenceLetterExists()) {
        navigate(session.path);
      } else {
        setErrorMessage("Please create an Refrence Letter first.");
      }
    } else {
      // Check for application ID in localStorage for other sessions
      if (checkApplicationExists()) {
        console.log("session.path",session.path)
        navigate(session.path);
      } else {
        setErrorMessage("Please create an application first.");
      }
    }
  };

  return (
    <nav>
      <div></div>
      <div className="mb-4">
        <div className="card text-left p-1">
          <div className="card-body p-3">
            <h4 className="card-title mb-2">{title}</h4>

            {/* Conditionally render error message */}
            {errorMessage && <p className="text-danger">{errorMessage}</p>}

            <div className="list-group">
              {sessions.map(session => (
                <div
                  key={session.id}
                  onClick={() => handleNavigation(session)}
                  className={`list-group-item list-group-item-action ${location.pathname === session.path ? 'active' : ''}`}
                  style={{ cursor: "pointer" }}
                >
                  {session.title}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Parser } from 'json2csv';
import FileSaver from 'file-saver';
import axios from 'axios';

const DashboardTable = ({ event, title, type,status ,gender,province}) => {
    console.log("event",event)
    console.log("title",title)
    console.log("type",type)
    console.log("status",status)
    console.log("gender",gender)
    console.log("province",province)

    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    console.log("token",token)
    const authUserInfo = JSON.parse(localStorage.getItem('auth_user_info')); // Get the stored JSON string and parse it to an object
    const userId = authUserInfo.id; // Access the 'id' property
    console.log("User ID:", userId);
    const navigate = useNavigate();
    const [applications, setApplications] = useState([]);
    const [realapplications, setRealApplications] = useState([]);

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    let apiCallURL = null;
    let api = null;

    if (type === "report") {
        apiCallURL = `${process.env.REACT_APP_BASE_URL}/applicant/report`
    } else {
        apiCallURL = `${process.env.REACT_APP_BASE_URL}/applicant/get-application-list`
    }
    if(status){
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[status][$eq]=${status}`
    }else if (gender){
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[gender][$eq]=${gender}`
    }
    else if(province){
      api = `${process.env.REACT_APP_BASE_URL_strapi}/applications?filters[province][$eq]=${province}`

    }

    // Fetch Applications information
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    apiCallURL,
                    {
                        headers: {
                            accept: "*/*",
                            Event: event,
                            SessionKey: "123",
                        },
                    }
                );
                const data = await response.json();
                setApplications(data.data);
                setMessage(data.message);
                // console.log(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching personal information data:", error);
                setLoading(false);
            }
        };

        fetchData();


        const fetchRealData = async () => {

            fetch(api, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`, // Your JWT token
                'Content-Type': 'application/json'
              }
            })
              .then(response => response.json())
              .then(applications => {
                console.log('Approved applications:', applications.data);
                setRealApplications(applications.data)
                // Handle your applications data here
              })
              .catch(error => {
                console.error('Error fetching approved applications:', error);
              });
        };

        fetchRealData();



   

    }, []);

    // // Fetch Applications information and download as Excel
    // const fetchDataAndDownload = async () => {
    //     try {
    //         const response = await axios.get(
    //             `${process.env.REACT_APP_BASE_URL}/applicant/get-application-list`,
    //             {
    //                 headers: {
    //                     accept: "*/*",
    //                     Event: event,
    //                     SessionKey: "123",
    //                 },
    //                 responseType: 'blob', // Tell axios to get the response as a Blob
    //             }
    //         );
    //         const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    //         FileSaver.saveAs(blob, 'data.xlsx');
    //         setLoading(false);

    //     } catch (error) {
    //         console.error("Error fetching personal information data:", error);
    //         setLoading(false);
    //     }
    // };

    // Fetch Applications information and download as CSV


    // Function to convert JSON data to CSV
    const jsonToCsv = (data) => {
      if (!data || data.length === 0) return '';
    
      // Extract column headers from the first object keys
      const headers = Object.keys(data[0].attributes).filter(
        (key) => key !== 'id' // You can add other fields to exclude if needed
      );
    
      // Add headers row
      const csvRows = [headers.join(',')];
    
      // Add data rows for every item in the dataset
      data.forEach((item) => {
        const row = headers.map((header) => {
          const value = item.attributes[header];
          return value !== null && value !== undefined ? value : ''; // Ensure no undefined values
        });
        csvRows.push(row.join(',')); // Join the values with a comma and push the row into the CSV array
      });
    
      return csvRows.join('\n'); // Join all rows with newlines to form the CSV string
    };

    // Fetch Applications information and download as CSV
    const fetchDataAndDownload = async () => {
      setLoading(true);
      
      try {
        // Fetch your real applications data from the server (example)

        console.log("realapplications ====>", realapplications);
    
        // Convert JSON data to CSV
        const csvData = jsonToCsv(realapplications);
    
        // Create Blob from CSV data
        const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });
    
        // Use FileSaver to save the Blob as a CSV file
        FileSaver.saveAs(blob, `applicant_data.csv`);
    
        setLoading(false);
      } catch (error) {
        console.error('Error fetching and downloading data:', error);
        setLoading(false);
      }
    };
        // if (type === "report") {
        //   console.log("1111")
        //     // Convert JSON data to CSV
        //     console.log("realapplications ====>",realapplications)
        //     const csvData = jsonToCsv(realapplications);
        //     // console.log(csvData);

        //     const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });

        //     // Use FileSaver to save the Blob as a CSV file
        //     FileSaver.saveAs(blob, `${title}.csv`);

        //     setLoading(false);
        // } else {
        //   console.log("2222")

        //     try {
        //         const response = await axios.get(
        //             `${process.env.REACT_APP_BASE_URL}/applicant/get-application-export-list`,
        //             {
        //                 headers: {
        //                     accept: "*/*",
        //                     Event: event,
        //                     SessionKey: "123",
        //                 }
        //             }
        //         );
        //         const data = response.data.data; // Extract the data array from the response

        //         // Check if data is null or undefined
        //         if (!data) {
        //             console.log('No data received');
        //             return;
        //         }


        //         // Convert JSON data to CSV
        //         const csvData = jsonToCsv(data);
        //         // console.log(csvData);

        //         // Create a Blob from the CSV data
        //         const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });

        //         // Use FileSaver to save the Blob as a CSV file
        //         FileSaver.saveAs(blob, 'data.csv');

        //         setLoading(false);

        //     } catch (error) {
        //         console.error("Error fetching personal information data:", error);
        //         setLoading(false);
        //     }
        // }


  
    const yes = async (id) => {
    
      if (!id || !token) {
        alert("Application ID or Token missing!");
        return;
      }
    
      try {
        // Step 1: Fetch the evaluation related to the specific application and logged-in user ${process.env.REACT_APP_BASE_URL_strapi}/evaluations?filters[application][id][$eq]=${id}&populate=*`
        const fetchEvaluationsResponse = await fetch( `${process.env.REACT_APP_BASE_URL_strapi}/users/me?populate[evaluations][filters][application][id][$eq]=${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token for authentication
          },
        });
    
        if (fetchEvaluationsResponse.ok) {
          const evaluationsData = await fetchEvaluationsResponse.json();
    
          // Ensure there's at least one evaluation found
          if (evaluationsData.evaluations && evaluationsData.evaluations.length > 0) {
            const evaluationId = evaluationsData.evaluations[0].id; // Get the first evaluation's ID
            
            // Step 2: Update the final_stage to true
            const updateResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations/${evaluationId}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Pass the token for authentication
              },
              body: JSON.stringify({
                data: {
                  final_stage: true, // Update the final_stage field to true
                },
              }),
            });
    
            if (updateResponse.ok) {
              console.log("Evaluation final stage updated successfully");
              alert("Evaluation final stage updated successfully!");



                // After creating the evaluation, fetch all evaluations related to the application
            const fetchEvaluationsResponse = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/evaluations?filters[application][id][$eq]=${id}&populate=*`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`, // Pass the token for authentication
              },
            });
      
            if (fetchEvaluationsResponse.ok) {
              const evaluationsData = await fetchEvaluationsResponse.json();
              console.log("All evaluations fetched successfully:", evaluationsData);
             
              // Map through the evaluations and count the number of next_stage true/false
                const result = evaluationsData.data.reduce(
                    (acc, val) => {
                      console.log("val.attributes.final_stage",val.attributes.final_stage)
                    if (val.attributes.final_stage == true) {
                        acc.trueCount++;
                    } else if(val.attributes.final_stage == false) {
                        acc.falseCount++;
                    } else {
                      
                    }
                    return acc;
                    },
                    { trueCount: 0, falseCount: 0 }
                );
                
                // Check the conditions and return the appropriate value
                let finalDecision = "none"; // Default value
                if (result.trueCount >= 2) {
                    let  outgoingData = {
                        status:'final_stage'
                      }           
                     try {
                        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
                          method: 'PUT', 
                          headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json', // Ensure content-type is set to JSON
                          },
                          body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                        });
                    
                        if (!response.ok) {
                          throw new Error('Failed to submit form');
                        }
                    
                        const data = await response.json();
                        console.log(data);
                      } catch (error) {
                        console.error('Error:', error);
                      }



                    finalDecision = "yes";
                } else if (result.falseCount >= 2) {
                  let  outgoingData = {
                    status:'rejected'
                  }
                    try {
                        const response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/applications/${id}`, {
                          method: 'PUT', 
                          headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json', // Ensure content-type is set to JSON
                          },
                          body: JSON.stringify({ data: outgoingData }) // Send the data wrapped in a 'data' object
                        });
                    
                        if (!response.ok) {
                          throw new Error('Failed to submit form');
                        }
                    
                        const data = await response.json();
                        console.log(data);
                      } catch (error) {
                        console.error('Error:', error);
                      }

                    finalDecision = "no";
                }
                
                console.log("Final decision:", finalDecision);
              // Handle the evaluations data (for example, update the state or display the data)
              // You can set the data in a state or manipulate it as needed
      
            } else {
              console.error("Failed to fetch evaluations:", fetchEvaluationsResponse);
              alert("Failed to fetch evaluations. Please try again.");
            }





            } else {
              console.error("Failed to update evaluation final stage:", updateResponse);
              alert("Failed to update evaluation. Please try again.");
            }
          } else {
            console.error("No evaluation found for this application and user");
            alert("No evaluation found for this application and user.");
          }
        } else {
          console.error("Failed to fetch evaluations:", fetchEvaluationsResponse);
          alert("Failed to fetch evaluations. Please try again.");
        }
      } catch (error) {
        console.error("Error updating evaluation:", error);
        alert("An error occurred while updating the evaluation.");
      }
    };
    
  const no = async (id) => {
    
    console.log("no",id)

      // try {
      //     const response = await axios.get(
      //         `${process.env.REACT_APP_BASE_URL}/applicant/get-application-export-list`,
      //         {
      //             headers: {
      //                 accept: "*/*",
      //                 Event: event,
      //                 SessionKey: "123",
      //             }
      //         }
      //     );
      //     const data = response.data.data; // Extract the data array from the response

      //     // Check if data is null or undefined
      //     if (!data) {
      //         console.log('No data received');
      //         return;
      //     }


      //     // Convert JSON data to CSV
      //     const csvData = jsonToCsv(data);
      //     // console.log(csvData);

      //     // Create a Blob from the CSV data
      //     const blob = new Blob([csvData], { type: 'text/csv;charset=UTF-8' });

      //     // Use FileSaver to save the Blob as a CSV file
      //     FileSaver.saveAs(blob, 'data.csv');

      //     setLoading(false);

      // } catch (error) {
      //     console.error("Error fetching personal information data:", error);
      //     setLoading(false);
      // }

};

    // const allKeys = ['id', 'name', 'address', 'education', 'experience', 'nationlity', 'gender', 'email', 'contact', 'status'];
    const allKeys = ['id', 'name', 'email'];


    return (
        <div>
            {loading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-bubble spinner-bubble-primary m-5"></div>
                </div>
            ) : (<div className="card">
                <div className="card-body">
                    <div className="simple-card-title d-flex justify-content-between align-items-center">
                        <h3 className="text-capitalize mb-1">{title}</h3>
                        <button className="btn-icon m-1 text-capitalize btn btn-primary text-white" onClick={fetchDataAndDownload}>
                            <span className="ul-btn__icon">
                                <i className="i-File-Excel"></i>
                            </span>
                            <span className="ul-btn__text">Import Data</span>
                        </button>
                    </div>
                    {realapplications && realapplications.length > 0 && (
  <table className="table table-bordered">
    <thead>
      <tr>
        {['id', 'first_name', 'primary_email', 'address', 'primary_phone_number'].map((key) => (
          <th key={key}>{key.charAt(0).toUpperCase().replace('_', ' ') + key.slice(1)}</th>
        ))}
        <th>Select</th>
      </tr>
    </thead>
    <tbody>
      {realapplications.map((application) => (
        <tr key={application.id}>
          {['id', 'first_name', 'primary_email', 'address', 'primary_phone_number'].map((key) => (
            <td key={key}>
              {key === 'id' ? application.id : application.attributes[key] || 'N/A'}
            </td>
          ))}
          <td>
            {status == 'approved' ?
            <div>
            <button
              type="button"
              className="btn-icon m-1 text-capitalize btn btn-outline-primary"
              onClick={() => yes(application.id)} // Use an arrow function to pass the value
              >
             
             Yes
            </button>
              <button
              type="button"
              className="btn-icon m-1 text-capitalize btn btn-outline-primary"
              onClick={() => no(application.id)} // Use an arrow function to pass the value
              >
              
             No
            </button>
            </div>
            :
            <button
            type="button"
            className="btn-icon m-1 text-capitalize btn btn-outline-primary"
            onClick={() => navigate(`/applications/view-application/${application.id}`)}
          >
            <span className="ul-btn__icon">
              <i className="i-Information"></i>
            </span>
            View
          </button>

                }
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  )}




                </div>
            </div>
            )}
        </div>
    );
};

export default DashboardTable;


// {
//     "data": [
//         {
//             "reference": "DeveDeem IS Demo Direct AT Demo Organization",
//             "address": "Living in Lahore, Faisalabad, Punjab",
//             "name": "Nadeem R Archer",
//             "id": 9,
//             "file": "http://techmac.ovh/rfmse/ReferenceFileUpload/Reference9",
//             "gender": "Male",
//             "disability": "No ",
//             "nationality": "Foreign National",
//             "contact": "+923012345678, +92515347372",
//             "email": "nademo@gmail.com, testnadeem@g.com",
//             "score": 0,
//             "graceScore": 0,
//             "resume": "http://techmac.ovh/rfmse/ReferenceFileUpload/Resume9",
//             "experience": "4",
//             "coverLetter": "Nadeem Statement",
//             "skills": "Nadeem skill",
//             "dob": "2023-12-21",
//             "network": "Linked In",
//             "totalScore": 0,
//             "fundingRequest": "1",
//             "education": "16"
//         },
//         {
//             "reference": "jjh IS yuu AT ytyt",
//             "address": "3Aj905hvflknh klre[oktregpijnm 12245----, Gakuch, Gilgit-Baltistan",
//             "name": "Muhammad  Mustafa",
//             "id": 23,
//             "file": "http://techmac.ovh/rfmse/ReferenceFileUpload/Reference23",
//             "gender": "Male",
//             "disability": "No ",
//             "nationality": "Foreign National",
//             "contact": "+923334383455",
//             "email": "mustafa-amjad@hotmail.com",
//             "score": 0,
//             "graceScore": 0,
//             "resume": "http://techmac.ovh/rfmse/ReferenceFileUpload/Resume23",
//             "experience": "4",
//             "coverLetter": "rtsytjhg hgjhjh hghgj ghh 4565  ytu 455666 5666 -- 00n hfgjh ",
//             "skills": "hjjytuyt ygyut n tyuyui ry t ",
//             "dob": "2222-02-22",
//             "network": "Website",
//             "totalScore": 0,
//             "fundingRequest": null,
//             "education": "16"
//         }
//     ],
//     "status": 200,
//     "message": "New Applications"
// }